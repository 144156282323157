import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import ActivityExample from "../shared/program/ActivityExample"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    lifeness: file(relativePath: { eq: "one/activity/lifeness.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    hiking: file(relativePath: { eq: "one/activity/event.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    learning: file(relativePath: { eq: "one/activity/vehicle.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    ocean: file(relativePath: { eq: "one/activity/outing.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
  }
`

const ProgramActivityExample = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      title: "ライフネス活動",
      description:
        "ライフネスジムへ行き、プロのトレーナさんにトレーニングをしていただいています💪ジムで本格的な道具を使用させていただくので気分も最高です☆みんなが大好きな活動です！",
      image: getImage(data.lifeness),
    },
    {
      title: "イベント行事",
      description:
        "春夏秋冬、季節に因んだ行事を大切にし、行事に取り入れることで、自然と楽しんで身について行けるように取り組んでいます🌸🌄🍁⛄その際の昼食やおやつも行事食にし、食育にも繋げております🍙焼き芋、お餅つき、山登り、神社参拝、夏祭りect.",
      image: getImage(data.hiking),
    },
    {
      title: "乗り物学習",
      description:
        "自分で切符を購入し、公共の電車、バスでマナーやルールを学びます。\nみんな乗り物大好きなので楽しく学べました。",
      image: getImage(data.learning),
    },
    {
      title: "外出活動",
      description:
        "春はお花見、夏は海、秋は遠足でハイキング、冬はスキー場 ect.\nその時期でしか味わえない活動を取り入れて、みんなで思いきり楽しんでいます♪楽しいなかにもルールやマナーがあるので、身をもって体験して学んで行けることがねらいです！",
      image: getImage(data.ocean),
    },
  ]
  return (
    <div>
      <HeadingM title="活動事例" target="one" />
      <ActivityExample items={items} />
    </div>
  )
}

export default ProgramActivityExample
